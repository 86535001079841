.a-colorPicker {
    $root: &;
    display: flex;
    align-items: center;
    cursor: pointer;

    &_circle {
        position: relative;
        width: rem(24);
        height: rem(24);
        flex-shrink: 0;
        margin-right: rem(12);

        &::after {
            position: absolute;
            border: 2px solid $spanish-gray;
            width: rem(24);
            height: rem(24);
            content: '';
            border-radius: 50%;
            left: 0;
            right: 0;
            opacity: 0;
        }

        &-checked {
            #{$root}_circleColor {
                width: rem(16);
                height: rem(16);
            }

            &::after {
                opacity: 1;
            }
        }
       
        &-white {
            &::before {
                position: absolute;
                border: 1px solid rgba($spanish-gray, 0.6);
                width: rem(24);
                height: rem(24);
                content: '';
                border-radius: 50%;
                left: 0;
                right: 0;
            }
        }
    }

    &_circleColor {
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(24);
        height: rem(24);
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
}
