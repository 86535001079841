.m-giftItem {
    width: 100%;
    @include adjust-flex(flex-start,stretch);

    &_img {
        width: rem(68);
    }


    &_detail {
        flex: 1 1;
        margin-left: rem(20);
        @include adjust-flex(space-evenly,flex-start);
        flex-direction: column;
    }  

    &_footer {
        @include adjust-flex(flex-start);

        .a-paragraph + .a-paragraph {
            margin-left: rem(40);
        }
    }  
}
