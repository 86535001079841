.m-collapseItem {
    $root: &;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(14) rem(20);

    &-isLabel {
        background-color: $white-smoke2;

        &.m-collapseItem-isExpand {
            #{$root}_title {
                .a-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &_title {
        display: flex;
        align-items: center;

        .a-icon {
            flex-shrink: 0;
            margin-right: rem(10);
            transition: $transition linear;
        }
    }
}

.p-detailCourseContent {
    padding: rem(20);
    background-color: $white;

    &_table {
        .m-collapse {
            border: 1px solid $isabelline2;

            &:not(:first-child) {
                margin-top: rem(12);
            }

            &_content {
                .m-collapseItem:not(:last-child) {
                    border-bottom: 1px solid $isabelline2;
                }
            }
        }
    }
}
