.m-tooltipWrap {
    $root: &;
    position: relative;
    width: 100%;

    &_content {
        z-index: 1;
        position: absolute;
        white-space: nowrap;
        bottom: calc(100% + 7px);
        left: 0;
        padding: rem(12);
        border-radius: rem(8);
        background-color: $jet;
        opacity: 0;
        visibility: hidden;
        transition: all $transition ease-in-out;
    }

    &-isShow {
        #{$root}_main {
            * {
                cursor: not-allowed;
            }
        }

        #{$root}_main:hover ~ #{$root}_content {
            opacity: 1;
            visibility: visible;
        }
    }

    &-isShowByClick { 
        #{$root}_content {
            animation: 1.5s linear showTooltipOnTimeRange;
        }
    }
}

@keyframes showTooltipOnTimeRange {
    0% {
        opacity: 1;
        visibility: visible;
    }

    50% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}
