.a-image {
    display: block;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    transition: $transition-long ease;
    width: 100%;

    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &-inherit {
        img {
            object-fit: inherit;
        }
    }

    &-initial {
        img {
            object-fit: initial;
        }
    }

    &-contain {
        img {
            object-fit: contain;
        }
    }

    &-1x1 {
        @include aspect-ratio(1, 1);
    }

    &-16x9 {
        @include aspect-ratio(16, 9);
    }

    &-754x298 {
        @include aspect-ratio(754, 298);
    }

    &-364x145 {
        @include aspect-ratio(364, 145);
    }

    &-176x54 {
        @include aspect-ratio(176, 54);
    }

    &-335x113 {
        @include aspect-ratio(335, 113);
    }

    &-624x298 {
        @include aspect-ratio(624, 298);
    }

    &-310x145 {
        @include aspect-ratio(310, 145);
    }

    &-315x99 {
        @include aspect-ratio(315, 99);
    }
    
    &-676x514 {
        @include aspect-ratio(676, 514);
    }

    &-442x253 {
        @include aspect-ratio(442, 253);
    }
    
    &-933x388 {
        @include aspect-ratio(933, 388);
    }

    &-354x224 {
        @include aspect-ratio(354, 224);
    }

    &-1366x418 {
        @include aspect-ratio(1366, 418);
    }
    
    &-667x768 {
        @include aspect-ratio(667, 768);
    }
    
    &-73x23 {
        @include aspect-ratio(73, 23);
    }
}
