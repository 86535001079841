.p-flowerCareDetail {
    &_image {
        pointer-events: none;
        
        &.play {
            position: relative;
            cursor: pointer;
            pointer-events: initial;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                content: '';
                background: $jet203;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url('~assets/icons/ic_play.svg');
                background-repeat: no-repeat;
                background-size: contain;
                height: rem(40);
                width: rem(40);
                z-index: 2;
            }
            
        }
    }
}
