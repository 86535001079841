.p-detailCoursePhaseLayout {
    $root: &;

    &_wrap {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-left: auto;
        transition: $transition linear;
        #{$root}-isExpand & {
            width: calc(100% - 312px);
        }
    }

    &_content {
        flex: 1;
        border-bottom: 1px solid $isabelline2;
        position: relative;
        #{$root}-isExpand & {
            border-left: 1px solid $isabelline2;
        }
    }
    
    &_contentAbs {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    &_bottom {
        width: 100%;
    }

    &_nav {
        height: 100vh;
        overflow: auto;
        position: fixed;
        width: rem(312);
        z-index: $z-header;
        left: rem(-312);
        top: 0;
        box-shadow: 0 2px 8px $black015;
        background-color: $white;
        transition: $transition linear;
        #{$root}-isExpand & {
            left: 0;
        }
    }

    &_btnClose {
        display: flex;
        justify-content: flex-end;
        padding: rem(4);

        button {
            @include reset-button();
            background-color: transparent;
        }
    }
}
