.p-detailCoursePhaseNavbar {
    .m-collapse {
        border-bottom: 1px solid $isabelline2;
    }

    .m-collapseItem {
        background-color: transparent;

        &-isExpand {
            border-bottom: 1px solid $isabelline2;
        }
    }
}
