/* stylelint-disable selector-no-vendor-prefix */
.p-detailCoursePhaseVideo {    
    flex-direction: column;
    display: flex;
    height: 100%;

    &_title {
        margin: 0 auto;
        width: 100%;
    }

    &_content {
        margin: 0 auto;
        width: 100%;
    }

    &_player {
        position: relative;
        display: flex;
        align-items: center;

        &_video {
            flex: 1;
        }

        &_controls {
            position: absolute;
            right: rem(50);
            bottom: rem(30);
            z-index: 2;

            .control {
                @include reset-button;
                background-color: transparent;
                margin-right: rem(10);
            }

            .expand_screen {
                @include reset-button;
                background-color: transparent;
                transition: $transition ease-in-out;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        &_bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            cursor: pointer;

            &-open {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $black03;
            }

            &_icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: rem(40);
                height: rem(40);
                @include adjust-flex;
            }
        }    
    }
}

:-webkit-full-screen .p-detailCoursePhaseVideo_player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-fullscreen;
    background-color: $black;
}
