$colors-bg-button: (
    'white': $white,
    'redPelati': $red-pelati,
    'northTexasGreen': $north-texas-green,
    'orangeBronze': $orange-bronze,
    'northTexasGreen02': $north-texas-green02,
    'whiteSmoke1': $white-smoke1,
    'rossoCorsa': $rosso-corsa,
    'isabelline2': $isabelline2
);

$colors-text-button: (
    'white': $white,
    'jet': $jet,
    'northTexasGreen': $north-texas-green,
    'eerieBlack': $eerie-black,
    'dodgerBlue': $dodger-blue,
    'raisinBlack':$raisin-black,
    'dimGray': $dim-gray,
    'rossoCorsa': $rosso-corsa
);

$colors-border-button: (
    'pastelGray': $pastel-gray,
    'eerieBlack': $eerie-black,
    'northTexasGreen': $north-texas-green,
    'dodgerBlue': $dodger-blue,
    'orangeBronze': $orange-bronze,
    'isabelline2': $isabelline2,
    'rossoCorsa': $rosso-corsa,
    'redPelati': $red-pelati,
    'darkTangerine':$dark-tangerine,
    'spanishGray': $spanish-gray
);


.a-button {
    $root: &;
    @include adjust-flex();
    @include reset-button();
    color: $white;
    font-family: inherit;
    font-weight: 600;
    position: relative;
    transition: transform $transition ease-in-out;
    width: 100%;
    white-space: nowrap;
    outline: none;
    
    @each $name, $color in $colors-bg-button {
        &-bg-#{$name} {
            background-color: $color;
        }
    }

    @each $name, $color in $colors-text-button {
        &-text-#{$name} {
            color: $color;

            &:hover {
                color: $color;
            }
        }
    }

    @each $name, $color in $colors-border-button {
        &-border-#{$name} {
            border: 1px solid $color;
        }
    }
  
    &:hover {
        box-shadow: 0 0 0 rem(3.5) $glitter;
    }

    &-xs {
        @include font-base(14, 20);
        height: rem(36);
        font-weight: 500;
        padding: 0 rem(16);
    }

    &-xs-large {
        @include font-base(14, 20);
        height: rem(40);
        padding: 0 rem(24);
    }

    &-sm-small {
        @include font-base(12, 16);
        height: rem(40);
        padding: 0 rem(12);
    }

    &-sm {
        @include font-base(14, 20);
        height: rem(42);
        padding: 0 rem(29);
    }

    &-md {
        @include font-base(16, 22);
        height: rem(48);
        padding: 0 rem(30);
    }
  
    &-lg {
        @include font-base(16, 22);
        height: rem(60);
        padding: 0 rem(30);
    }

    &_text {
        position: relative;
        z-index: 2;
    }
    
    &-loading {
        pointer-events: none;
        #{$root}_text {
            opacity: 0;
        }
  
        #{$root}_loading {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
        }
    }

    &-borderRadius {
        border-radius: rem(99);
    }
    
    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }

    &-bg-rossoCorsa:disabled {
        background-color: $isabelline2;
        color: $jet;
        opacity: 1;
    }

    &-bg-white:disabled {
        background-color: $isabelline2;
    }
}
