@import './container/Layout/';
@import './container/SignIn/';
@import './container/ForgotPassword/';

.p-newLogin {
    $root: &;

    &_layout_innerForm {
        #{$root}.signup & {
            max-height: 100vh;
            overflow: auto;
            height: auto;
        }
    }
  
}
