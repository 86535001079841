.p-newLogin_forgotPassword {
    &_identify,
    &_verify,
    &_newPassword {
        @include adjust-flex;
       
        > * {
            flex: 0 0 100%;
        }
    }

    &_verify {
        .m-otpInput {
            &_error {
                text-align: center;
            }
        }
    }
}
