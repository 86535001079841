.m-pulldown {
    $root: &;
    position: relative;

    &-disabled {
        opacity: 0.7;
        #{$root}_header {
            cursor: not-allowed;
        }
    }
  
    &_label {
        @include font-base(16, 22);
        color: $jet;
        display: inline-block;
        font-weight: 600;
        margin-bottom: rem(12);
    }
  
    &_header {
        @include adjust-flex(flex-end, center);
        background: $white;
        border: 1px solid $isabelline2;
        cursor: pointer;
        height: rem(48);
        padding: rem(14) rem(20);
        position: relative;
        transition: all $transition linear;
        width: 100%;
  
        &_content {
            @include font-base(14, 20);
            align-items: center;
            color: $eerie-black;
            display: flex;
            flex-grow: 1;
            font-weight: 600;
            height: 100%;
            overflow: hidden;
            width: 100%;

            span {
                @include text-overflow(1);
            }
        }
    }
  
    &_placeholder {
        color: $spanish-gray;
    }
  
    &_fn {
        align-items: center;
        display: flex;
        flex-shrink: 0;
    }

    &_icon {
        margin-right: rem(8);
    }
  
    &_arrow {
        display: block;
        height: rem(20);
        position: relative;
        width: rem(20);
  
        &::after {
            background: url('~assets/icons/ic_arrow_down.svg') no-repeat;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: $transition;
            width: 100%;
        }
  
        &.opened {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
  
    &_clear {
        margin-right: rem(3);
        padding: rem(6);
        position: relative;

        &::after {
            background: $north-texas-green;
            content: '';
            height: rem(14);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
        }
    }

    &_loading {
        margin-right: rem(3);
        padding: rem(6);
        position: relative;
    }
  
    &_wrapper {
        background: $white;
        margin-top: rem(4);
        position: absolute;
        width: 100%;
        z-index: 15;
    }
  
    &_list {
        @include font-base(14, 20);
        @include scroll-bars(8);
        background: $white;
        border: 1px solid $isabelline2;
        color: $black085;
        font-weight: 600;
        margin: 0;
        max-height: rem(141);
        overflow-y: auto;
        padding: 0;
    }
  
    &_item {
        cursor: pointer;
        list-style: none;
        padding: rem(8) rem(16);
        transition: all $transition linear;
        white-space: normal;
  
        &:hover {
            background-color: $north-texas-green;
            color: $white;
        }
  
        &-active {
            background-color: $north-texas-green;
            color: $white;
        }
    }
  
    &-error {
        #{$root} {
            &_header {
                border-color: $error;
                color: $error;

                &_content {
                    color: $error;
                }
            }
  
            &_placeholder {
                color: $error;
            }
        }
  
        #{$root}_error {
            @include font-base(12, 20);
            color: $error;
            margin-top: rem(4);
        }
    }

    &_inputSearch {
        width: 100%;
        border: 0;
        outline: none;
        appearance: none;
        color: $eerie-black;
        @include font-base(14, 20);
        font-weight: 500;
        border-radius: rem(4);
    }
}
