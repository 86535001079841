.p-detailCoursePhaseBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(16) rem(20);
    flex-wrap: wrap;

    > div {
        margin-right: rem(20);

        &:nth-child(1) {
            margin-right: 7%;
        }
    }
}
