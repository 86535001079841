.o-tabsLink {
    display: flex;
    align-items: center;
    @include scroll-bars(10);
    overflow: auto;

    &_item + &_item {
        margin-left: rem(12);
    }

    &_item {
        display: block;
        white-space: nowrap;
        padding: rem(12);

        &:hover {
            .a-paragraph {
                color: $north-texas-green;
            }
        }

        &.active {
            background-color: $aero-blue;

            .a-paragraph {
                color: $north-texas-green;
            }
        }
    }
}
