.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    &-12 {
        height: rem(12);
        width: rem(12);
    }

    &-14 {
        height: rem(14);
        width: rem(14);
    }

    &-16 {
        height: rem(16);
        width: rem(16);
    }

    &-20 {
        height: rem(20);
        width: rem(20);
    }

    &-22 {
        height: rem(22);
        width: rem(22);
    }

    &-22x14 {
        height: rem(14);
        width: rem(22);
    }

    &-24 {
        height: rem(24);
        width: rem(24);
    }

    &-26 {
        height: rem(26);
        width: rem(26);
    }

    &-28 {
        height: rem(28);
        width: rem(28);
    }

    &-32 {
        height: rem(32);
        width: rem(32);
    }
    
    &-33 {
        height: rem(33);
        width: rem(33);
    }

    &-35 {
        height: rem(35);
        width: rem(35);
    }

    &-36 {
        height: rem(36);
        width: rem(36);
    }

    &-40 {
        height: rem(40);
        width: rem(40);
    }

    &-60 {
        height: rem(60);
        width: rem(60);
    }

    &-heart {
        background-image: url('~assets/icons/ic_heart.svg');
    }

    &-heartWhite {
        background-image: url('~assets/icons/ic_heart_white.svg');
    }

    &-plusWhite {
        background-image: url('~assets/icons/ic_plus_white.svg');
    }

    &-minusGray {
        background-image: url('~assets/icons/ic_minus_gray.svg');
    }

    &-minusGreen {
        background-image: url('~assets/icons/ic_minus_green.svg');
    }
  
    &-plusGreen {
        background-image: url('~assets/icons/ic_plus_green.svg');
    }

    &-close {
        background-image: url('~assets/icons/ic_close_black.svg');
    }

    &-arrowLeftGray {
        background-image: url('~assets/icons/ic_arrow_left_gray.svg');
    }

    &-arrowLeftOrange {
        background-image: url('~assets/icons/ic_arrow_left_orange.svg');
    }

    &-arrowRightGray {
        background-image: url('~assets/icons/ic_arrow_right_gray.svg');
    }

    &-arrowRightOrange {
        background-image: url('~assets/icons/ic_arrow_right_orange.svg');
    }

    &-arrowLeftWhite {
        background-image: url('~assets/icons/ic_arrow_left_white.svg');
    }

    &-arrowRightWhite {
        background-image: url('~assets/icons/ic_arrow_right_white.svg');
    }
  
    &-loadingWhite {
        background-image: url('~assets/icons/ic_loading_white.svg');
    }

    &-loadingGray {
        background-image: url('~assets/icons/ic_loading_gray.svg');
    }

    &-loadingGreen {
        background-image: url('~assets/icons/ic_loading_green.svg');
    }

    &-loadingJet {
        background-image: url('~assets/icons/ic_loading_jet.svg');
    }

    &-arrowOrange {
        background-image: url('~assets/icons/ic_arrow_orange.svg');
    }
    
    &-prevPaginate {
        background-image: url('~assets/icons/ic_prev_paginate.svg');
    }

    &-nextPaginate {
        background-image: url('~assets/icons/ic_next_paginate.svg');
    }

    &-heartGreen {
        background-image: url('~assets/icons/ic_heart_green.svg');
    }
  
    &-shoppingOrange {
        background-image: url('~assets/icons/ic_shopping_orange.svg');
    }

    &-shoppingCartGreen {
        background-image: url('~assets/icons/ic_shopping_cart_green.svg');
    }
  
    &-notifyGray {
        background-image: url('~assets/icons/ic_notify_gray.svg');
    }

    &-userGray {
        background-image: url('~assets/icons/ic_user_gray.svg');
    }

    &-notepad {
        background-image: url('~assets/icons/ic_notepad.svg');
    }

    &-person {
        background-image: url('~assets/icons/ic_person.svg');
    }

    &-clipboardText {
        background-image: url('~assets/icons/ic_clipboard_text.svg');
    }

    &-eye {
        background-image: url('~assets/icons/ic_eye.svg');
    }

    &-heartGray {
        background-image: url('~assets/icons/ic_heart_gray.svg');
    }

    &-power {
        background-image: url('~assets/icons/ic_power.svg');
    }
  
    &-closeGray {
        background-image: url('~assets/icons/ic_close_gray.svg');
    }

    &-dismissCircle {
        background-image: url('~assets/icons/ic_dismiss_circle.svg');
    }

    &-checkCircle {
        background-image: url('~assets/icons/ic_check_circle.svg');
    }
    
    &-shoppingCartWhite {
        background-image: url('~assets/icons/ic_shopping_cart_white.svg');
    }

    &-cartBagWhite {
        background-image: url('~assets/icons/ic_cart_bag_white.svg');
    }

    &-headphoneWhite {
        background-image: url('~assets/icons/ic_headphone_white.svg');
    }

    &-gridGreen {
        background-image: url('~assets/icons/ic_grid_green.svg');
    }

    &-gridGray {
        background-image: url('~assets/icons/ic_grid_gray.svg');
    }

    &-listGreen {
        background-image: url('~assets/icons/ic_list_green.svg');
    }

    &-listGray {
        background-image: url('~assets/icons/ic_list_gray.svg');
    }

    &-arrowRightGreen {
        background-image: url('~assets/icons/ic_arrow_right_green.svg');
    }

    &-facebook {
        background-image: url('~assets/icons/ic_facebook.svg');
    }

    &-instagram {
        background-image: url('~assets/icons/ic_instagram.svg');
    }

    &-youtube {
        background-image: url('~assets/icons/ic_youtube.svg');
    }

    &-navigationWhite {
        background-image: url('~assets/icons/ic_navigation_white.svg');
    }

    &-closeCircle {
        background-image: url('~assets/icons/ic_close_circle.svg');
    }

    &-playWhite {
        background-image: url('~assets/icons/ic_play_white.svg');
    }

    &-starOrangeOutline {
        background-image: url('~assets/icons/ic_star_orange_outline.svg');
    }

    &-starOrange {
        background-image: url('~assets/icons/ic_star_orange.svg');
    }

    &-playWhiteCircle {
        background-image: url('~assets/icons/ic_play.svg');
    }

    &-downBlack {
        background-image: url('~assets/icons/ic_down_black.svg');
    }

    &-playGraySmall {
        background-image: url('~assets/icons/ic_play_gray_small.svg');
    }

    &-playBlueSmall {
        background-image: url('~assets/icons/ic_play_blue_small.svg');
    }

    &-closeCircleBlack {
        background-image: url('~assets/icons/ic_close_circle_black.svg');
    }

    &-fullscreen {
        background-image: url('~assets/icons/ic_fullscreen.svg');
    }

    &-cancelFullscreen {
        background-image: url('~assets/icons/ic_cancel_fullscreen.svg');
    }

    &-controlPause {
        background-image: url('~assets/icons/ic_control_pause.svg');
    }

    &-controlPlay {
        background-image: url('~assets/icons/ic_control_play.svg');
    }
    
    &-gridByThreeGray {
        background-image: url('~assets/icons/ic_grid_by_three_gray.svg');
    }

    &-gridByThreeGreen {
        background-image: url('~assets/icons/ic_grid_by_three_green.svg');
    }

    &-gridByFiveGray {
        background-image: url('~assets/icons/ic_grid_by_five_gray.svg');
    }

    &-gridByFiveGreen {
        background-image: url('~assets/icons/ic_grid_by_five_green.svg');
    }

    &-buildingGreen {
        background-image: url('~assets/icons/ic_building_green.svg');
    }

    &-mapGreen {
        background-image: url('~assets/icons/ic_map_green.svg');
    }

    &-phoneGreen {
        background-image: url('~assets/icons/ic_phone_green.svg');
    }

    &-locationGreen {
        background-image: url('~assets/icons/ic_location_green.svg');
    }

    &-mailGreen {
        background-image: url('~assets/icons/ic_mail_green.svg');
    }

    &-lockGreen {
        background-image: url('~assets/icons/ic_lock_green.svg');
    }

    &-personGreen {
        background-image: url('~assets/icons/ic_person_green.svg');
    }

    &-eyeBlack {
        background-image: url('~assets/icons/ic_eye_black.svg');
    }

    &-eyeBlackOff {
        background-image: url('~assets/icons/ic_eye_black_off.svg');
    }

    &-arrowLeftBlack {
        background-image: url('~assets/icons/ic_arrow_left_black.svg');
    }

    &-listBlue {
        background-image: url('~assets/icons/ic_list_blue.svg');
    }

    &-arrowLeftBlackDeep {
        background-image: url('~assets/icons/ic_arrow_left_black_deep.svg');
    }
    
    &-arrowRightBlackDeep {
        background-image: url('~assets/icons/ic_arrow_right_black_deep.svg');
    }
    
    &-nounAlert {
        background-image: url('~assets/icons/ic_noun_alert.svg');
    }

    &-gift {
        background-image: url('~assets/icons/ic_gift.svg');
    }

    &-addCircle {
        background-image: url('~assets/icons/ic_add_circle.svg');
    }

    &-news {
        background-image: url('~assets/icons/ic_news.svg');
    }
    
    &-arrowRightBlue {
        background-image: url('~assets/icons/ic_arrow_right_blue.svg');
    }

    &-voucher {
        background-image: url('~assets/icons/ic_voucher.svg');
    }

    &-delete {
        background-image: url('~assets/icons/ic_delete.svg');
    }

    &-popupDelete {
        background-image: url('~assets/icons/ic_PopupDelete.svg');
    }

    &-voucher1 {
        background-image: url('~assets/icons/ic_voucher1.svg');
    }

    &-sale {
        background-image: url('~assets/icons/ic_sale.svg');
    }

    &-gif {
        background-image: url('~assets/icons/ic_gif.svg');
    }
    
    &-nounAlertBlack {
        background-image: url('~assets/icons/ic_noun_alert_black.svg');
    }

    &-tooltipArrow {
        background-image: url('~assets/icons/ic_tooltip_arrow.svg');
    }

    &-notification {
        background-image: url('~assets/icons/ic_notification.svg');
    }
}
