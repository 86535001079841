.o-cardEmpty {
    &_wrap {
        max-width: rem(354);
        margin: 0 auto;
    }

    &_image {
        max-width: rem(214);
        margin: 0 auto;
    }
}
