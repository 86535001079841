.aboutSection {
    background: url('~assets/images/about_section_bg.jpg') no-repeat center / cover;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: $white095;
        z-index: 1;
    }

    &_inner {
        position: relative;
        z-index: 2;
    }
}

