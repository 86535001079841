.o-container {
    @include break-min($standard-viewport) {
        margin-left: auto;
        margin-right: auto;
        max-width: $standard-viewport;
        padding-left: rem(120);
        padding-right: rem(120);
    }
  
    &-fullScreen {
        padding-left: 0;
        padding-right: 0;
    }


    &_row {
        margin-left: rem(-16);
        margin-right: rem(-16);
    }

    &_col {
        padding-left: rem(16);
        padding-right: rem(16);
    }
}
