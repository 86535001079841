.m-badgeItem {
    @include adjust-flex(flex-start, center);
    background-color: transparent;
    border: 0;
    border-radius: rem(500);
    padding: rem(4) rem(10) rem(4) rem(4);
    text-align: left;

    &:hover {
        background-color: $black007;
    }

    &_icon {
        @include adjust-flex(center, center);
        background-color: $white-smoke;
        border-radius: 50%;
        height: rem(46);
        margin-right: rem(12);
        width: rem(46);
        position: relative;
    }

    &_badgeNumber {
        @include font-base(12, 16.34);
        @include adjust-flex(center, center);
        background-color: $red-pelati;
        border: rem(1.5) solid $white;
        border-radius: 50%;
        color: $white;
        font-weight: 600;
        height: rem(22);
        width: rem(22);
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(20%, -20%);
    }
}
