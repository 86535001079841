.m-collapse {
    &_content {
        max-height: 0;
        overflow: hidden;
        transition: $transition ease-in-out;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
