.m-dateRangePicker {
    display: inline-flex;
    align-items: center;

    padding: rem(4) rem(11);


    &_label {
        margin-right: rem(16);
        @include font-base(14, 19);
        font-weight: 600;
    }

    &_startDate,
    &_endDate {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
  
        .react-datepicker__input-container {
            position: relative;
            border: 1px solid $isabelline2;

            &:hover {
                border-color: $eerie-black;
            }

            input {
                padding: rem(12) rem(15) rem(11) rem(48);
                background-position: left rem(15) top 50%;
                background-repeat: no-repeat;
                font-family: inherit;
                font-weight: 600;
                font-size: rem(14);
                transition: all $transition ease-in-out;
                width: 100%;
                max-width: rem(160);
            }
        }
    }
  
    &_startDate {
        .react-datepicker__input-container {
            input {
                background-image: url('~assets/icons/ic_calendar.svg');
                background-size: rem(18);
            }
        }
    }
  
    &_endDate {
        padding-left: rem(12);

        .react-datepicker__input-container {
            input {
                background-image: url('~assets/icons/ic_calendar.svg');
                background-size: rem(18);
            }
        }
    }

    &_header {
        @include adjust-flex;
    }
  
    &_calendar {
        background-color: $white;
        padding: rem(24) rem(16);
        box-shadow: 0 rem(30) rem(84) $black0043, 0 rem(8) rem(32) $black0043, 0 rem(3) rem(14) $black0043,
            0 1px rem(13) $black0043;
 
  
        .react-datepicker__day--in-range,
        .react-datepicker__day--in-selecting-range {
            background-color: $north-texas-green02;
        }
  
        .react-datepicker__day-names {
            display: flex;
            margin-bottom: 0;
        }
  
        .react-datepicker__day--outside-month,
        .react-datepicker__day--disabled {
            opacity: 0.4;
        }
  
        .react-datepicker__month {
            margin: 0;
        }
  
        .react-datepicker__day-name {
            @include font-base(14, 19);
            align-items: center;
            color: $spanish-gray;
            display: flex;
            flex-direction: column;
            font-weight: 600;
            height: rem(40);
            justify-content: center;
            width: rem(40);
        }
  
        .react-datepicker__week {
            display: flex;
        }
  
        .react-datepicker__day {
            align-items: center;
            color: $spanish-gray;
            display: flex;
            flex-direction: column;
            font-weight: 600;
            height: rem(40);
            justify-content: center;
            width: rem(40);
            @include font-base(14, 19);
            cursor: pointer;
        }
  
        .react-datepicker__day--today {
            background: $isabelline2;
            color: $north-texas-green;
            font-weight: 600;
        }
  
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--selected {
            background: $north-texas-green;
            color: $white;
        }
    }
  
    &_wrapper {
        border: 0;
        outline: none;
        width: 100%;
    }

    .m-pulldown {
        $pulldown: &;

        &_header {
            border: 0;

            &_content {
                color: $gray;
            }
        }

        &_wrapper {
            min-width: fit-content;
        }

        &_fn {
            margin-left: rem(10);
        }
    }
}
