.m-infoDelivery {
    &_content {
        height: 100%;
        padding: rem(16);
        border-radius: rem(8);
        background-color: $white-smoke1;
    }

    &_button {
        width: fit-content;
        @include adjust-flex();
        @include reset-button();
        background-color: transparent;
        padding: 0;

        .a-icon {
            margin-left: rem(4);
        }
    }

}
