// fonts
$font-path: '~assets/fonts/';
$font-inter: 'Inter';
$font-open-sans: 'Open Sans';
// transition
$transition: 0.25s;
$transition-medium: 0.3s;
$transition-long: 0.4s;

// z-index
$z-header: 100;
$z-modal: 101;
$z-1: 1;
$z-2: 2;
$z-loading: 999;
$z-scroll-to-top: 98;
$z-fullscreen: 9999;
$z-download-popup: 99999;

// space
$height-header-desktop: 100;
$height-header-desktop-scroll: 76;
$height-header-tablet-down: 65;

$standard-viewport: 1366px;

$offsets: (top, bottom, left, right);
$breakpoints: (
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
);
