.p-newsWholesaleCustomer {
    &_banner {
        display: grid;
        grid-gap: rem(8);
        grid-template-columns: 60.5fr 39.5fr;
        grid-template-rows: 1fr 1fr;

        &_item {
            &:first-child {
                grid-row: 1/3;
            }
        }
    }
}
