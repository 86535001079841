.o-footerRegister {
    background: url('~assets/images/bg_register.jpg') center / cover;

    &_input {
        max-width: rem(495);
        width: 100%;

        .a-input_input-button {
            background-color: $orange-bronze;
        }
    }
}
