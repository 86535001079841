.o-productDetailInfo {
    &_info {
        &_heart {
            margin-top: rem(8);
        }
  
        &_btnHeart {
            display: inline-block;
            cursor: pointer;
        }
  
        &_des {
            margin-top: rem(20);
            padding-bottom: rem(20);
            border-bottom: 1px solid $isabelline2;
        }

        &_left {
            border-right: 1px solid $isabelline2;
            padding-right: rem(40);
        } 

        &_content {
            padding-bottom: rem(20);
            border-bottom: 1px solid $isabelline2;
        }

        &_button {
            max-width: rem(168);

            button:disabled {
                background-color: $rosso-corsa;
                color: $white;
            }
        }
        
        &_wrapListDiscount {
            display: flex;
            flex-wrap: wrap;
            margin: rem(-2);
        }
    
        &_wrapItem {
            padding: rem(2);
        }
    
        &_item {
            background: $dodger-blue005;
            border: 1px solid $dodger-blue;
            padding: rem(4) rem(8);
            border-radius: rem(3);
        }
    }
}
  
.o-slideImage {
    position: relative;

    &_main {
        flex-shrink: 0;
        position: relative;

        .slick-list {
            margin-bottom: rem(-5);
        }

        .o-carousel_arrow {
            &_prev {
                left: rem(10);
            }

            &_next {
                right: rem(10);
            }
        }

        &-icon {
            @include adjust-flex();
            background: $black02;
            border: 1px solid $white04;
            border-radius: rem(20);
            height: rem(28);
            position: absolute;
            right: rem(8);
            top: rem(8);
            width: rem(28);
            cursor: pointer;

            &::after {
                content: '';
                background-image: url('~assets/icons/ic_heart_white.svg');
                width: rem(16);
                height: rem(16);
                background-repeat: no-repeat;
                background-size: contain;
            }

            &:hover ,
            &-active {
                background-color: $north-texas-green;
                border-color: $north-texas-green;
            }
        }
    }

    &_slide {
        position: absolute;
        background: rgba(9, 10, 10, 0.6);
        border-radius: rem(16);
        padding: rem(4) rem(12);
        bottom: rem(12);
        right: rem(12);
    }

    &_listIcon {
        position: absolute;
        bottom: rem(12);
        left: rem(12);
    }

}
