@import './Register/';

.o-footer {
    &_brand {
        max-width: rem(335);
    }

    &_main {
        border-bottom: 1px solid $isabelline2;
    }

    &_menuItem {
        &:hover {
            .a-paragraph {
                opacity: 0.7;
            }
        }
    }
}
