@import './RelationshipTag/';
@import './BadgeItem/';
@import './Dropdown/';
@import './HeaderTopPanel/';
@import './TitlePage/';
@import './CategoryDropdown/';

.o-header {
    border-bottom: rem(1) solid $white-smoke;
    background-color: $white;

    &_topActions {
        @include adjust-flex(flex-start, center);

        &_actionItem {
            @include adjust-flex(flex-start, center);
            margin-right: rem(8);
            gap: rem(7);
            cursor: pointer;

            &-notify {
                margin-right: rem(23);
            }

            &.anonymous {
                margin-right: 0;
            }
        }
    }

    &_elements {
        @include adjust-flex(space-between, flex-start);
        margin-top: rem(11);
        transition: $transition ease-in-out;
        cursor: pointer;

        &_panel {
            flex-grow: 1;
            padding-left: rem(20);
        }

        &_wrapper {
            @include adjust-flex(space-between, center);

            .m-badgeItem {
                flex-shrink: 0;
                white-space: nowrap;
                margin-left: rem(40);
            }
        }

        &_logo {
            margin-top: rem(5);
            width: rem(176);
            flex-shrink: 0;
        }

        &_search {
            width: 100%;
        }

        &_navigation {
            @include adjust-flex(flex-start, center);    
            flex-wrap: wrap;
            margin-top: rem(27);
        }

        &_link {
            @include font-base(14, 20);
            padding-bottom: rem(12);
            color: $eerie-black;
            font-weight: 500;
            text-transform: uppercase;
            transition: border-bottom $transition linear;
            position: relative;
            margin-right: rem(28);
            
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $north-texas-green;
                height: rem(2);
                width: 100%;
                transform: scale(0);
                transition: transform $transition linear;
                transform-origin: center;
            }
            
            &:hover {
                color: $north-texas-green;
            }

            &.active::before {
                transform: scale(1);
            }

        }
    }
}
