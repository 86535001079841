.o-carousel {
    $root: &;
    position: relative;
  
    &_arrow {
        $arrow: &;
        border-radius: 50%;
        height: rem(32);
        transition: $transition-long ease;
        width: rem(32);
        z-index: 1;
  
        &.default {
            border: 1.5px solid $orange-bronze;
        }

        &.white {
            background: $white02;
            box-shadow: 0 2px 8px $charcoal02;
        }

        &.whiteAround {
            width: rem(28);
            height: rem(28);
            background: $eerie-black02;
            border: 1px solid $white04;
        }

        &.greenAround {
            width: rem(28);
            height: rem(28);
            background: $eerie-black02;
            border: 1px solid $white04;

            &:hover {
                background-color: $north-texas-green;
            }
        }
  
        &::before {
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: rem(20);
            content: '';
            display: block;
            height: 100%;
            opacity: 1;
            transition: $transition-long ease;
            width: 100%;
        }

        &_prev {
            left: rem(-52);
  
            &.default {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_left_orange.svg');
                }

                &.slick-disabled::before {
                    background-image: url('~assets/icons/ic_arrow_left_gray.svg');
                }
            }

            &.white {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_left_white.svg');
                    background-size: rem(16);
                }
            }

            &.whiteAround {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_left_white_around.svg');
                    background-size: rem(16);
                }
            }

            &.greenAround {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_left_white_around.svg');
                    background-size: rem(16);
                }
            }
        }
  
        &_next {
            right: rem(-52);
  
            &.default {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_right_orange.svg');
                }
  
                &.slick-disabled::before {
                    background-image: url('~assets/icons/ic_arrow_right_gray.svg');
                }
            }

            &.white {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_right_white.svg');
                    background-size: rem(16);
                }
            }

            &.whiteAround {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_right_white_around.svg');
                    background-size: rem(16);
                }
            }

            &.greenAround {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_right_white_around.svg');
                    background-size: rem(16);
                }
            }
        }
  
        &.default {
            &.slick-disabled {
                border-color: $gray-x11;
                cursor: no-drop;
            }
  
            &::before {
                opacity: 1;
            }
        }
  
    }
  
    .slick-slide {
        outline: none;
  
        div {
            &:focus {
                outline: 0;
            }
        }
    }
    
    .slick-dots {
        bottom: 0;
        display: flex !important;
        justify-content: center;
        position: relative;

        li {
            display: block;
            height: auto;
            margin: 0 rem(2);
            width: auto;
        }
    }
  
    &-outDots {
        .slick-dots {
            margin-top: rem(20);
        }
    }
  
    &-innerDots {
        .slick-dots {
            bottom: rem(96);
            margin-top: 0;
            position: absolute;
        }
    }
  
    &_dot {
        &.circle-white {
            background-color: $white04;
            border-radius: 50%;
            display: block;
            height: rem(6);
            width: rem(6);
        }
  
        &.rect-orange {
            background-color: $orange-bronze05;
            border-radius: 1px;
            display: block;
            height: rem(3);
            transition: $transition-long ease;
            width: rem(16);
        }

        &.circle-orange {
            background-color: $orange-bronze05;
            border-radius: rem(4);
            display: block;
            height: rem(8);
            width: rem(8);
            transition: $transition-long ease-in-out;
        }

        &.circle-green {
            background-color: $gainsboro;
            border-radius: 50%;
            display: block;
            height: rem(8);
            width: rem(8);
        }
    }
  
    .slick-active {
        #{$root}_dot {
            &.circle-white {
                background-color: $white;
                transform: scale(1.33);
            }
  
            &.rect-orange {
                background-color: $orange-bronze;
                border-radius: 4px;
                width: rem(32);
            }

            &.circle-orange {
                background-color: $orange-bronze;
                width: rem(32);
            }

            &.circle-green {
                background-color: $north-texas-green;
            }    
        }
    }
}
