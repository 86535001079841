.p-detailCourseMentor {
    padding: rem(20);
    background-color: $white;

    &_image {
        width: rem(92);
        border-radius: 50%;
        overflow: hidden;
    }
}
