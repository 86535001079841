.o-categoryDropdown {
    $root: &;
    position: relative;

    &:hover {
        #{$root}_drop {
            top: calc(100% + 2px);
            visibility: visible;
            opacity: 1;
        }
    }

    &_btn {
        cursor: pointer;
        background-color: $north-texas-green;
        padding: rem(6) rem(16);
        display: flex;
        align-items: center;

        .a-icon {
            flex-shrink: 0;
            margin-right: rem(10);
        }
    }

    &_drop {
        position: absolute;
        z-index: 1;
        left: 0;
        top: calc(100% + 20px);
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: $transition linear;
    }

    &_list {
        background-color: $white;
        border: 1px solid $isabelline2;
        box-shadow: 0 2px 8px $black015;
    }

    &_item {
        cursor: pointer;
        padding: rem(12) rem(20);

        &.active,
        &:hover {
            .a-paragraph {
                color: $north-texas-green;
            }
        }
    }
}
