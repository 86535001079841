.p-newLogin_layout {
    $root: &;

    @include adjust-flex(space-between, stretch);
    min-height: 100vh;

    > * {
        flex-shrink: 0;
        width: 50%;
    }

    &_image {
        .a-image {
            height: 100%;
            padding-bottom: 0;
        }
    }

    &_innerForm {
        > * {
            height: 100%;
            margin-left: auto;
            margin-right: auto;
            max-width: rem(550);
        }
    }
    
}
