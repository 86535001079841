.p-newsDetailBanner {
    $root: &;

    &_item {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(360deg, rgba($black, 0.44) 3.35%, rgba($black, 0) 104.31%);
            pointer-events: none;
        }
        
        &_content {
            position: absolute;
            bottom: rem(124);
            left: 50%;
            transform: translateX(-50%);
            max-width: rem(878);
            width: 100%;
            z-index: 2;
        }

        &_title {
            @include text-overflow(2);
        }

        &_description {
            @include text-overflow(3);
        }
    }

    .o-carousel {
        &_arrow {
            top: auto;
            left: auto;
            bottom: rem(40);
    
            &.slick-next {
                right: 50%;
                transform: translateX(calc(100% + 10px));
            }
    
            &.slick-prev {
                left: 50%;
                transform: translateX(calc(-100% - 10px));
            }
        }

        &-innerDots {
            .slick-dots {
                li:not(:last-child) {
                    margin-right: rem(12);
                } 
            }
        }
    }

}
