.p-checkout {
    margin-top: rem(-16);

    &_note {
        max-width: rem(547);
    }

    &_btnGoBack {
        width: fit-content;
        @include adjust-flex();

        .a-icon {
            margin-right: rem(8);
        }
    }

    &_table {
        padding: rem(20);
        border: 1px solid $isabelline2;

        table {
            width: 100%;
        }

        thead {
            border-bottom: 1px solid $isabelline2;

            th {
                padding-bottom: rem(18);
            }
        }

        tbody {
            td {
                padding-top: rem(25);
            }
        }
    }

    &_product {
        &_info {
            display: flex;
            align-items: center;
        }

        &_infoLeft {
            margin-right: rem(12);
            flex-shrink: 0;
        }

        &_infoRight {
            display: flex;
        }

        &_image {
            width: rem(68);
            flex-shrink: 0;
            margin-right: rem(12);
        }

        &_inputNumber {
            display: flex;
            justify-content: flex-end;
        }

        &_button {
            @include reset-button();
            background-color: transparent;
            height: rem(32);
            margin-left: auto;
            padding: 0;
            display: block;
        }
    }

    &_groupBtnLeft {
        width: rem(197);
    }

    &_groupBtnRight {
        width: rem(354);
    }

    &_price {
        padding: rem(20);
        border: 1px solid $isabelline2;
    }

    &_input {
        @include adjust-flex;

        input {
            width: 100%;
            padding: rem(14) rem(20);
            @include font-base(14, 20);
            font-weight: 500;
            color: $eerie-black;
            outline: none;
            border: 1px solid $isabelline2;
            background-color: $white;

            &::placeholder {
                color: $spanish-gray;
            }
        }

        button {
            @include font-base(14, 20);
            @include reset-button;
            position: relative;
            height: rem(50);
            padding: 0 rem(28);
            flex-shrink: 0;
            flex-basis: 100%;
            max-width: rem(104);
            background-color: $north-texas-green;
            font-weight: 500;
            color: $white;

            &:disabled {
                background-color: $isabelline2;
                color: $spanish-gray;
            }

            .a-icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &.error {
            input {
                border-color: $red-pelati;
                color: $red-pelati;
            }
        }
    }

    &_voucherList {
        padding-left: rem(30);
        position: relative;

        &::before {
            position: absolute;
            left: rem(15);
            top: 0;
            width: 2px;
            height: 100%;
            content: '';
            background-color: $pastel-gray;
        }

        &_wrapper {
            padding: rem(5);
            max-height: rem(180);
            overflow: auto;

            @include scroll-bars(6);

            :not(:first-child) {
                margin-top: rem(12);
            }
        }
    }

    &_divider {
        background-color: $isabelline;
        height: 1px;
    }
}
