.o-cardNewLayer {
    $root: &;
    position: relative;
    @include thumbnail-scale-hover();

    &_info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        background: linear-gradient(180deg, rgba($black, 0) 12.66%, $black 100%);

        #{$root}-large & {
            padding: rem(48) rem(20) rem(33);
        }

        #{$root}-small & {
            padding: rem(16) rem(20);
        }
    }

    &_btnPlayer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('~assets/icons/ic_play.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: rem(40);
        width: rem(40);
        z-index: 2;
        cursor: pointer;
    }
}
