.t-detailCourseProgress {
    height: rem(20);
    background-color: $isabelline2;
    position: relative;

    &_slide {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1;
        background-color: $medium-sea-green;
    }

    &_value {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.t-detailCourseInfo {
    &_image {
        cursor: pointer;
        position: relative;

        &_icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &_btnPlay {
        width: fit-content;

        .a-button {
            height: rem(42);
            padding: 0 rem(16);
        }
    }
}
