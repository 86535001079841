.m-deliver {
    @include adjust-flex(space-between);

    &_date {
        color: $orange-bronze;
    }

    &_groupBtn {
        @include adjust-flex;

        button {
            @include adjust-flex();
            @include reset-button();
            background-color: transparent;
            padding: 0;
            margin-left: rem(12);

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
