.o-filter {
    &_group {
        padding: rem(16);
        max-height: rem(472);
        overflow-y: scroll;
        @include scroll-bars(8);

        &-item {
            padding: rem(12) rem(3);
        }
    }

    &_groupWrap {
        overflow: hidden;
        border-radius: rem(8);
        background-color: $white-smoke248;
    }

    &_groupLabel {
        $label-root: &;
        position: relative;
        @include adjust-flex(space-between);

        &-icon {
            transition: all $transition ease-in-out;
            transform: rotate(0deg);

            svg {
                path {
                    fill: $eerie-black;
                }
            }
        }

        &-isOpen {
            #{$label-root}-icon {
                transform: rotate(180deg);

                svg {
                    path {
                        fill: $north-texas-green;
                    }
                }
            }
        }
    }

    &_actions {
        @include adjust-flex(space-between);
        margin-top: rem(20);

        :first-child {
            max-width: rem(89);
            flex-basis: 100%;
        };

        :last-child {
            max-width: rem(160);
            flex-basis: 100%;
        };
    }

    &_colors {
        max-height: rem(335);
        overflow-y: scroll;
        @include scroll-bars(8);
    }
}
