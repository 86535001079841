.p-orderList {
    margin-top: rem(-16);

    &_btnGoBack {
        button {
            @include adjust-flex();
            @include reset-button();
            background-color: transparent;
            padding: 0;

            .a-icon {
                margin-right: rem(8);
            }
        }
    }
}
