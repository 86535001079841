html,
body {
    font-family: $font-inter, sans-serif;
    font-size: 16px;

    @include desktop-down {
        height: 100vh;
    }
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

@each $offset in $offsets {
    @for $spacing from -24 through 250 {
        @if $spacing >= 0 {
            .u-m#{str-slice($offset, 0, 1)}-#{$spacing} {
                margin-#{$offset}: #{$spacing}px;
            }
  
            .u-p#{str-slice($offset, 0, 1)}-#{$spacing} {
                padding-#{$offset}: #{$spacing}px;
            }
        } @else {
            .u-m#{str-slice($offset, 0, 1)}-negative#{$spacing} {
                margin-#{$offset}: #{$spacing}px;
            }
        }
    }
    .u-m#{str-slice($offset, 0, 1)}-auto {
        margin-#{$offset}: auto;
    }
}
  
@each $name, $value in $breakpoints {
    @each $offset in $offsets {
        @for $spacing from -24 through 250 {
            @if $spacing >= 0 {
                .u-m#{str-slice($offset, 0, 1)}-#{$name}-#{$spacing} {
                    @media #{$value} {
                        margin-#{$offset}: #{$spacing}px;
                    }
                }
  
                .u-p#{str-slice($offset, 0, 1)}-#{$name}-#{$spacing} {
                    @media #{$value} {
                        padding-#{$offset}: #{$spacing}px;
                    }
                }
            } @else {
                .u-m#{str-slice($offset, 0, 1)}-negative-#{$name}#{$spacing} {
                    @media #{$value} {
                        margin-#{$offset}: #{$spacing}px;
                    }
                }
            }
        }
    }
}

.divider {
    width: 100%;
    border-top: rem(1) solid $isabelline2;
}

.cursor-pointer {
    cursor: pointer;
}

.editor {
    * {
        max-width: 100%;
    }

    ul,
    ol {
        padding: revert;
        margin: revert;
    }
}


.u-pl-5-important {
    padding-left: rem(10) !important;
}
