.m-otpInput {
    $root: &;

    &_container {
        display: flex;
        align-items: center;
    }

    &_item {
        margin-right: rem(20);

        &:last-child {
            margin-right: 0;
        }
    }

    &_input {
        position: relative;

        input {
            @include font-base(18,22);
            width: rem(48);
            height: rem(48);
            border: 1px solid $isabelline2;
            text-align: center;
            font-weight: 500;
            color: $north-texas-green;
            

            &:focus {
                outline: none;
                border-color: $north-texas-green;

                &::placeholder {
                    color: transparent;
                }
            }

            &:disabled {
                &::placeholder {
                    color: $isabelline2;
                }
            }

        }
    }
    
    &-error {
        #{$root}_input {
            input {
                border-color: $error;

                &::placeholder {
                    color: $error;
                }
            }
        }

        #{$root}_error {
            margin-top: rem(16);
            color: $error;
        }
    }
}
