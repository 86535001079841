.m-dropdown {
    $root: &;
    cursor: pointer;
    position: relative;

    &_content {
        padding-top: rem(12);
        position: absolute;
        top: calc(100% + 30px);
        opacity: 0;
        visibility: hidden;
        transition: $transition linear;
        z-index: 3;
        min-width: rem(173);

        #{$root}-notify & {
            min-width: rem(332);
        }

        #{$root}-open & {
            opacity: 1;
            visibility: visible;
            top: calc(100% + 3px);
        }
    }

    &_list {
        position: relative;
        border: 1px solid $isabelline2;
        box-shadow: 0 6px 8px $black015;
        background-color: $white;
  

        &::before {
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            content: '';
            border-bottom: rem(9) solid $isabelline2;
            border-right: rem(7) solid transparent;
            border-left: rem(7) solid transparent;

            #{$root}-notify & {
                left: rem(50);
            }
        }

        &::after {
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            content: '';
            border-bottom: rem(8) solid $white;
            border-right: rem(6) solid transparent;
            border-left: rem(6) solid transparent;

            #{$root}-notify & {
                left: rem(50);
            }
        }
    }

    &_listItem {
        max-height: rem(500);
        overflow: auto;
        @include scroll-bars();
        padding: 0 rem(16);
    }

    &_userItem {
        display: block;
        @include font-base(14,20);
        color: $jet;
        font-weight: 500;
        padding: rem(6) 0;
        margin-top: rem(6);

        &:last-child {
            margin-bottom: rem(6);
        }

        &:hover,
        &.active {
            color: $north-texas-green;
        }
    }

    &_notifyItem {
        display: flex;
        align-items: center;
        padding: rem(12) 0;
        border-bottom: 1px solid $isabelline2;

        .a-paragraph:last-child {
            margin-top: rem(4);
        }

        &:first-child {
            margin-top: rem(4);
        }

        &:last-child {
            border-bottom: 0;
            margin-bottom: rem(4);
        }

        &_image {
            width: rem(48);
            margin-right: rem(12);
            flex-shrink: 0;
        }

        &_empty {
            padding: rem(16) 0;
        }

        &-seen {
            opacity: 0.5;
        }
    }
}
