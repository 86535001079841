.a-radio {
    cursor: pointer;
    $root: &;
    @include adjust-flex(flex-start,center);

    &_input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: none;
    
        &:checked + #{$root}_holder {
            background-color: $north-texas-green;
            border: unset;
    
            &::after {
                border: rem(3) solid $white;
            }
        }
    }

    &_holder {
        width: rem(20);
        height: rem(20);
        border-radius: 50%;
        border: 1px solid $pastel-gray;
        position: relative;
        flex-shrink: 0;

        &::after {
            content: ' ';
            width: rem(18);
            height: rem(18);
            border-radius: 50%;
            background-color: transparent;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }
    }
}

