.a-loading {
    &-fullScreen {
        align-items: center;
        background-color: $white04;
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: $z-loading;
    }

    &-block {
        display: flex;
        justify-content: center;
        padding: rem(100) 0;
    }
}
