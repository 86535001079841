.p-confirm {
    &_note {
        max-width: rem(547);
    }
    
    &_table {
        padding: rem(20);
        border: 1px solid $isabelline2;
        
        table {
            width: 100%;
        }
        
        thead {
            border-bottom: 1px solid $isabelline2;
        
            th {
                padding-bottom: rem(18);
            }
        }
        
        tbody {
            td {
                padding-top: rem(25);
            }
        }
        
    }

    &_product {
        &_info {
            display: flex;
            align-items: center;
        }

        &_infoLeft {
            margin-right: rem(12);
            flex-shrink: 0;
        }

        &_infoRight {
            display: flex;
        }

        &_image {
            width: rem(68);
            flex-shrink: 0;
            margin-right: rem(12);
        }

        &_inputNumber {
            display: flex;
            justify-content: flex-end;
        }

        &_button {
            @include reset-button();
            background-color: transparent;
            height: rem(32);
            margin-left: auto;
            padding: 0;
            display: block;
        }
    }

    &_groupBtnLeft {
        width: rem(197);
    }

    &_groupBtnRight {
        width: rem(354);
    }

    &_price {
        padding: rem(20);
        border: 1px solid $isabelline2;
    }

    &_divider {
        background-color: $isabelline;
        height: 1px;
    }

    &_priceBoard {
        &_wrapper {
            position: relative;
        }
    }

    &_gifts {
        padding: rem(16);
        background: $green-crayola005;

        &_image {
            width: rem(68);
            margin-right: rem(12);
        }
    }
}
