.a-textArea {
    $root: &;

    &_label {
        @include font-base(16, 22);
        color: $jet;
        font-weight: 600;
        margin-bottom: rem(12);
        display: inline-block;
    }
  
    &_input {
        display: flex;

        textarea {
            @include font-base(14, 20);
            border: rem(1) solid $isabelline;
            color: $eerie-black;
            font-family: inherit;
            font-weight: 600;
            resize: none;
            outline: 0;
            padding: rem(16) rem(20);
            width: 100%;
    
            &::placeholder {
                @include font-base(14,20);
                color: $spanish-gray;
            }
        }
    }

    &-error {
        #{$root}_input {
            textarea {
                border-color: $error;
                color: $error;

                &::placeholder {
                    color: $error;
                }
            }
        }
    }

    &_error {
        @include font-base(12, 20);
        color: $error;
        margin-top: rem(4);
    }
}
