.p-detailCourseDocument {
    &_title {
        @include adjust-flex(flex-start);
        padding: rem(20);

        .a-icon {
            margin-right: rem(8);
        }
    }
}
