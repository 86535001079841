.t-layout {
    $root: &;

    &_content {
        min-height: calc(100vh - 166px - 442px);
        #{$root}-personal & {
            min-height: calc(100vh - 124px - 442px);
        }
    }

    .badge-info {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        position: fixed;
        z-index: 99;
        transition: 0.8s linear;
        background-color: $white-smoke;

        .a-image {
            border-radius: 50%;
        }

        &_quantity {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
            background-color: $red-pelati;
            color: $white;
            font-size: rem(12);
            width: rem(20);
            height: rem(20);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            z-index: 2;
        }
    }
}
