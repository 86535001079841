.o-popupPlayer {
    position: relative;

    &_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        cursor: pointer;

        &-open {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $black03;
        }

        &_icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: rem(48);
            height: rem(48);
            border-radius: rem(18);
            background-color: $black05;
            @include adjust-flex;
        }
    }
}
