
.o-popupGift {
    padding: rem(20);

    &_gifts {
        @include scroll-bars(8);
        max-height: rem(400);
        overflow: auto;

        &_item {
            padding: rem(16) 0;
    
            &:not(:last-child) {
                border-bottom: 1px solid $isabelline2;
            }
    
            .m-giftItem {
                margin-left: rem(22);
            }
        }
    }


    &_action {
        @include adjust-flex(end);  

        .a-button {
            width: auto;

            &:first-child:hover {
                box-shadow: unset;
            }

            &:last-child {
                margin-left: rem(16);
            }
        }
    }
}
