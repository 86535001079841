.c-flatList {
    &_header {
        @include adjust-flex(space-between, baseline);
        margin-bottom: rem(24); 
    }

    > .o-carousel > .slick-slider > .slick-list {
        margin: rem(-16);

        > .slick-track {
            margin-left: unset;
            display: flex;

            > .slick-slide {
                height: inherit;
                padding: rem(16);
                display: flex;
                flex-direction: column;

                > div {
                    display: flex;
                    flex-grow: 1;

                    > .o-carousel_wrap {
                        @include adjust-flex;
                        flex-direction: column;
                        flex-grow: 1;

                        >  .o-carousel_item {
                            height: 100%;
                        }
                    }
                }
             
            }
        }
    }
}
