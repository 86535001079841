.newHero {
    $root: &;

    &_contentWrapper {
        @include adjust-flex;
        height: 100%;
        padding-left: rem(96);
        padding-right: rem(96);
        border: 1px solid $gainsboro;
    }

    // &_title {
    //     @include text-overflow(2);
    // }

    // &_desc {
    //     @include text-overflow(4);
    // }

    &_cta {
        width: fit-content;
    }

    // Layout modifier 
    &-rect {
        #{$root}_image {
            .a-image {
                @include aspect-ratio(7,4);
            }
        }
        #{$root}_contentWrapper {
            border-width: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-right {
        flex-direction: row-reverse;
    }

    &_image {
        position: relative;

        &-overlay {
            background: $jet203;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
  
        &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url('~assets/icons/ic_play.svg');
            background-repeat: no-repeat;
            background-size: contain;
            height: rem(40);
            width: rem(40);
            cursor: pointer;
            z-index: 2;
        }
    }
}
