.m-tooltip {
    &_icon {
        display: inline-block;
        position: relative;
        cursor: pointer;

        &:hover ~ .m-tooltip_message ,
        &:hover .m-tooltip_arrow {
            visibility: visible;
            opacity: 1;
        }
    }

    &_message {
        max-width: rem(320);
        width: fit-content;
        padding: rem(12);
        background-color: $jet;
        border-radius: rem(8);
        position: absolute;
        bottom: calc(100% + 9px);
        left: 0;
        opacity: 0;
        visibility: hidden;
    }

    &_arrow {
        position: absolute;
        bottom: calc(100% + 5px);
        transform: translateX(-50%);
        left: 50%;
        opacity: 0;
        visibility: hidden;
    }
}
