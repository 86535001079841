.a-inputNumberWrapper {
    position: relative;
}

.a-inputNumber {
    $root: &;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 1px solid $isabelline2;
    display: flex;
    width: rem(120);
    padding: rem(6);
    border-radius: rem(99);
    
    &-widthSmall {
        width: rem(102);
    }

    &_button {
        align-items: center;
        cursor: pointer;
        display: block;
        display: flex;
        flex-shrink: 0;
        height: rem(24);
        justify-content: center;
        width: rem(24);
        position: relative;
        border-radius: 50%;

        &.disabled {
            cursor: not-allowed;
        }

        &.minus {
            &::before {
                position: absolute;
                width: rem(11.5);
                height: rem(1.5);
                border-radius: rem(4);
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: '';
            }

            &.disabled {
                background-color: $isabelline2;

                &::before {
                    background-color: $pastel-gray;
                }
            }

            &:not(.disabled) {
                background-color: $red-pelati;

                &::before {
                    background-color: $white;
                }
            }
        }

        &.add {
            &::before {
                position: absolute;
                width: rem(11.5);
                height: rem(1.5);
                border-radius: rem(4);
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: '';
            }

            &::after {
                position: absolute;
                width: rem(1.5);
                height: rem(11.5);
                border-radius: rem(4);
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: '';
            }

            &.disabled {
                background-color: $isabelline2;

                &::after,
                &::before {
                    background-color: $pastel-gray;
                }
            }

            &:not(.disabled) {
                background-color: $north-texas-green;

                &::after,
                &::before {
                    background-color: $white;
                }
            }
        }
    }

    &_purchaseBtn {
        width: rem(146);
        position: relative;

        &-text {
            @include font-base(14, 22);
            font-weight: 600;
            color: $white;
            margin-left: rem(4);
        }

    }
    
    &_input {
        @include font-base(14, 20);
        appearance: none;
        border: 0;
        color: $eerie-black;
        flex: 1;
        font-family: inherit;
        font-weight: 500;
        outline: none;
        padding: 0 rem(8);
        text-align: center;
        width: 100%;
    }

    &.toggle {
        background-color: $red-pelati;
        border-color: $red-pelati;
        cursor: pointer;
    }

}

.a-inputNumberInner {
    @include adjust-flex();

    &_cartQuantity {
        @include adjust-flex();
        background-color: $red-pelati;
        border-radius: rem(20);
        height: rem(32);
        min-width: rem(32);
        margin-left: rem(12);
        color: $white;
        @include font-base(12, 16);
        @include desktop-down {
            margin-left: rem(5);
        }

        &.disable {
            background-color: $pastel-gray;
        }
    }
}
