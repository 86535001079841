.p-cart {
    $root: &;
    margin-top: rem(-16);

    &_btnGoBack {
        width: fit-content;
        @include adjust-flex();

        .a-icon {
            margin-right: rem(8);
        }
    }

    &_table {
        padding: rem(20);
        border: 1px solid $isabelline2;
    
        table {
            width: 100%;
        }
    
        thead {
            border-bottom: 1px solid $isabelline2;
    
            th {
                padding-bottom: rem(18);
            }
        }
    
        tbody {
            td {
                padding-top: rem(25);
            }
        }
    }

    &_product {
        &_info {
            display: flex;
            align-items: center;
        }

        &_infoLeft {
            margin-right: rem(12);
            flex-shrink: 0;
        }

        &_infoRight {
            display: flex;
        }

        &_image {
            width: rem(68);
            flex-shrink: 0;
            margin-right: rem(12);
        }

        &_inputNumber {
            display: flex;
            justify-content: flex-end;
        }

        &_button {
            @include reset-button();
            background-color: transparent;
            height: rem(32);
            margin-left: auto;
            padding: 0;
            display: block;
        }

        &_wrapListDiscount {
            display: inline-block;
            margin: rem(-2);
        }
    
        &_wrapItem {
            padding: rem(2);
        }
    
        &_item {
            background: $dodger-blue005;
            border: 1px solid $dodger-blue;
            padding: rem(4) rem(8);
            border-radius: rem(3);
        }
    }

    &_groupBtnLeft {
        width: rem(197);
    }

    &_groupBtnRight {
        width: rem(354);
    }

    &_giftTable {
        background-color: $green-crayola005;
        padding: rem(16);

        #{$root}_product_infoRight {
            align-items: center;
        }

        &-title {
            @include adjust-flex(flex-start);

            :first-child {
                margin-right: rem(4);
            }
        }

        &-item {
            &:not(:first-child) {
                margin-top: rem(12);
            }
        }
    }
}
