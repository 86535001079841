.o-cardProduct {
    $root: &;
    height: 100%;
    display: flex;
    flex-direction: column;

    &-horizontal {
        flex-direction: row;
        flex-wrap: wrap;

        #{$root}_thumbnail {
            flex: 0 0 47%;
            max-width: 47%;
        }

        #{$root}_content {
            flex: 0 0 53%;
            max-width: 53%;
            padding: 0 rem(20);
            margin-top: 0;
            border: 1px solid $isabelline;
            justify-content: center;
        }

        #{$root}_title {
            flex: initial;
        }

        #{$root}_wrapPrice {
            padding: rem(12) 0;
        }

        #{$root}_info {
            ul {
                li {
                    font-weight: 400;
                }
            }
        }
    }

    &_wrapListDiscount {
        display: flex;
        flex-wrap: wrap;
        margin: rem(-2);
    }

    &_wrapItem {
        padding: rem(2);
    }

    &_item {
        background: $dodger-blue005;
        border: 1px solid $dodger-blue;
        padding: rem(4) rem(8);
        border-radius: rem(3);
        background-color: $white;
    }


    &_thumbnail {
        flex-shrink: 0;
        position: relative;

        &_slider {
            .slick-list {
                margin-bottom: rem(-5);
            }

            .o-carousel_arrow {
                &_prev {
                    left: rem(10);
                }

                &_next {
                    right: rem(10);
                }
            }
        }

        &_onlyOne {
            @include thumbnail-scale-hover();
        }

        &-icon {
            @include adjust-flex();
            background: $black02;
            border: 1px solid $white04;
            border-radius: rem(20);
            height: rem(32);
            position: absolute;
            right: rem(8);
            top: rem(8);
            width: rem(32);
            cursor: pointer;

            &::after {
                content: '';
                background-image: url('~assets/icons/ic_heart_white.svg');
                width: rem(20);
                height: rem(20);
                background-repeat: no-repeat;
                background-size: contain;
            }

            &:hover ,
            &-active {
                background-color: $north-texas-green;
                border-color: $north-texas-green;
            }
        }

        &_listIcon {
            position: absolute;
            bottom: rem(10);
            left: rem(5);
        }
    }

    &_absDiscount {
        position: absolute;
        bottom: rem(38);
        pointer-events: none;
        left: rem(5);
    }

    &_wrapTitle {
        @include text-overflow;
    }

    &_bgDiscount {
        padding: 0 rem(2);
        border-radius: rem(4);
        background-color: $light-brilliant-red02;
    }

    &-default {
        #{$root}_content {
            margin-top: rem(12);
        }
        #{$root}_wrapTitle {
            min-height: rem(44);
        }
    }

    &-gridFive {
        #{$root}_content {
            margin-top: rem(8);
        }
        #{$root}_wrapTitle {
            min-height: rem(40);
        }
        #{$root}_item {
            padding: rem(3);  
        }
    }
}
