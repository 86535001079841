.m-floatingButton {
    background-color: $white-smoke;
    border-radius: 50%;
    width: rem(48);
    height: rem(48);
    position: fixed;
    right: rem(30);
    transform: translateY(rem(68));
    transition: 0.3s linear;
    z-index: $z-scroll-to-top;
    cursor: pointer;
    @include adjust-flex;  

    &-active {
        transform: translateY(0);
    }

    &_badge {
        @include font-base(12, 16.34);
        @include adjust-flex(center, center);
        background-color: $red-pelati;
        border: 1px solid $white;
        border-radius: 50%;
        color: $white;
        font-weight: 600;
        height: rem(22);
        width: rem(22);
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(20%, -20%);
    }

}
