.o-cardLayer {
    $root: &;
    height: 100%;
    display: flex;
    flex-direction: column;

    &_thumbnail {
        position: relative;
        flex-shrink: 0;
  
        @include thumbnail-scale-hover();

        &-overlay {
            background: $jet203;
            position: absolute;
            inset: 0;
        }
  
        &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url('~assets/icons/ic_play.svg');
            background-repeat: no-repeat;
            background-size: contain;
            height: rem(40);
            width: rem(40);
            cursor: pointer;
        }
    }

    &_content {
        margin-top: rem(24);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
  
    &_title {
        @include text-overflow();
        margin-top: rem(8);
    }
  
    &_desc {
        margin-top: rem(16);
        margin-bottom: auto;
        @include text-overflow(4);
    }

    &_button {
        margin-top: rem(20);
        @include adjust-flex(flex-start, center);

        .a-icon {
            margin-left: rem(4);
        }
    }
}
