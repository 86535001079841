.a-paragraph {
    $root: &;
    @include style-color();
    @include style-font-weight();
    @include style-general();
    @include style-fonts();
    margin: 0;
    transition: $transition ease;
  
    &-inline {
        display: inline-block;
    }

    &-10x14 {
        @include font-base(10, 14);
    }

    &-12x16 {
        @include font-base(12, 16);
    }

    &-12x18 {
        @include font-base(12, 18);
    }

    &-14x18 {
        @include font-base(14, 18);
    }

    &-14x20 {
        @include font-base(14, 20);
    }
  
    &-16x22 {
        @include font-base(16, 22);
    }
    
    &-18x26 {
        @include font-base(18, 26);
    }

    &-16x24 {
        @include font-base(16, 24);
    }
  
    &-20x32 {
        @include font-base(20, 32);
    }
  
    &-24x32 {
        @include font-base(24, 32);
    }

    &-28x38 {
        @include font-base(28, 38);
    }
}
