.p-detailCourseComment {
    padding: rem(20);
    background-color: $white;

    &_btn {
        width: fit-content; 
    }

    .a-textArea_input {
        textarea {
            padding: rem(8) rem(12);
            font-weight: 500;

            &:placeholder {
                color: $dim-gray;
            }
        }
    }
}
