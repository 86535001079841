@use 'sass:math';

@mixin text-overflow ($number: 2) {
    -webkit-box-orient: vertical;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: ($number);
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin aspect-ratio($width: 1, $height: 1) {
    padding-bottom: ($height / $width) * 100%;
}

@mixin transition($value) {
    transition: $value;
}

@mixin scroll-bars($size: 12,
$thumb-color: $north-texas-green,
$track-color: $isabelline,
$border-radius-thumb: 5,
$border-thumb: 2) {

    // For Internet Explorer

    scrollbar-face-color: $thumb-color;
    scrollbar-track-color: $track-color;

    // For Google Chrome
    &::-webkit-scrollbar {
        height: rem($size);
        margin-right: rem(10);
        width: rem($size);
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border: rem($border-thumb) solid $track-color;
        border-radius: rem($border-radius-thumb);
    }

    &::-webkit-scrollbar-track {
        background: $track-color;
    }
}

@mixin font-base($font-size: 16, $line-height: 24) {
    font-size: rem($font-size);
    line-height: rem($line-height);
}

@mixin style-bg-color() {
    &-bg-white {
        background-color: $white;
    }
}

@mixin style-font-weight() {
    &-100 {
        font-weight: 100;
    }

    &-200 {
        font-weight: 200;
    }

    &-300 {
        font-weight: 300;
    }

    &-400 {
        font-weight: normal;
    }

    &-500 {
        font-weight: 500;
    }

    &-600 {
        font-weight: 600;
    }

    &-700 {
        font-weight: bold;
    }

    &-800 {
        font-weight: 800;
    }

    &-900 {
        font-weight: 900;
    }
}

@mixin style-general() {
    &-uppercase {
        text-transform: uppercase;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-underline {
        text-decoration: underline;
    }

    &-lineThrough {
        text-decoration: line-through;
    }

    &-italic {
        font-style: italic;
    }

    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }

    &-right {
        text-align: right;
    }

    &-left {
        text-align: left;
    }
}

@mixin reset-button {
    appearance: none;
    background-color: none;
    border: 0;
    outline: none;
}

@mixin list-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin adjust-flex($justify-content: center, $align-item: center) {
    align-items: $align-item;
    display: flex;
    justify-content: $justify-content;
}

$colors-design: (
    'white': $white,
    'black': $black,
    'redPelati': $red-pelati,
    'northTexasGreen': $north-texas-green,
    'orangeBronze': $orange-bronze,
    'licorice': $licorice,
    'davysGrey': $davys-grey,
    'auroMetaSaurus': $auro-meta-saurus,
    'eerieBlack': $eerie-black,
    'dimGray': $dim-gray,
    'jet': $jet,
    'grayPastel': $gray-pastel,
    'spanishGray': $spanish-gray,
    'darkJungleGreen': $dark-jungle-green,
    'darkTangerine': $dark-tangerine,
    'raisinBlack': $raisin-black,
    'dartmouthGreen': $dartmouth-green,
    'dodgerBlue': $dodger-blue,
    'grayX11': $gray-x11,
    'goGreen': $go-green,
    'sangria': $sangria,
    'rossoCorsa': $rosso-corsa
);

@mixin style-color {
    @each $name,
        $color in $colors-design {
        &-#{$name} {
            color: $color;
        }
    }
}

$fonts-design: (
    'fontInter': $font-inter,
    'fontOpenSans': $font-open-sans,
);

@mixin style-fonts {
    @each $name,
        $font in $fonts-design {
        &-#{$name} {
            font-family: $font;
        }
    }
}

@mixin thumbnail-scale-hover {
    overflow: hidden;

    &:hover {
        .a-image {
            transform: scale(1.2);
        }
    }
}
