.a-input {
    $root: &;
  
    &_label {
        @include font-base(16, 22);
        color: $jet;
        display: inline-block;
        font-weight: 600;
        margin-bottom: rem(12);
    }

    &-search {
        #{$root}_input {
            input {
                &::placeholder {
                    color: $gray;
                }
            }
        }
    }

    &_wrapper {
        @include adjust-flex(flex-start, center);
    }
  
    &_input {
        @include font-base(14, 20);
        @include adjust-flex(flex-start, center);
        padding: rem(14) rem(20);
        border: rem(1) solid $isabelline;
        height: rem(48);
        flex-grow: 1;
        background-color: $white;

        input {
            color: $eerie-black;
            padding: 0;
            font-family: inherit;
            font-weight: 600;
            border-width: 0;
            outline: 0;
            width: 100%;
  
            &::placeholder {
                @include font-base(14,20);
                color: $spanish-gray;
                font-weight: 400;
            }
  
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                display: none;
            }
        }
  
    }

    &_button {
        @include adjust-flex();
        @include font-base(14, 20);
        align-self: stretch;
        background-color: $north-texas-green;
        color: $white;
        cursor: pointer;
        flex-shrink: 0;
        min-width: rem(120);
    }
  
    &-error {
        #{$root}_input {
            border-color: $error;

            input {
                color: $error;

                &::placeholder {
                    color: $error;
                }
            }
        }
  
        #{$root}_error {
            @include font-base(12, 20);
            color: $error;
            margin-top: rem(4);
        }
    }

    &_icon {
        margin-right: rem(8);
    }

    &_eye {
        cursor: pointer;
    }

}
