.o-bannerHome {
    &_wrap {
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0 rem(-4);
    }

    &_left {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 rem(4);

        &-three {
            flex: 0 0 66.4%;
            max-width: 66.4%;  
        }   
    }

    &_right {
        flex: 0 0 33.6%;
        max-width: 33.6%;
        display: flex;
        flex-direction: column;
        padding: 0 rem(4);
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: inherit;

        > div,
        .o-carousel_wrap,
        .o-carousel_item {
            height: 100%;
        }
    }

    .o-carousel_arrow {
        &_next {
            right: rem(10);
        }

        &_prev {
            left: rem(10);
        }
    }
}
