.o-sidebar {
    $root: &;

    &_item {
        & + & {
            margin-top: rem(20);
        }
    }

    &_link {
        display: flex;
        align-items: center;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &:hover,
        &.active {
            .a-paragraph {
                color: $north-texas-green;
            }

            .a-icon {
                #{$root}_item-notepad & {
                    background-image: url('~assets/icons/ic_notepad_green.svg');
                }
                #{$root}_item-person & {
                    background-image: url('~assets/icons/ic_person_green.svg');
                }
                #{$root}_item-clipboardText & {
                    background-image: url('~assets/icons/ic_clipboard_text_green.svg');
                }
                #{$root}_item-eye & {
                    background-image: url('~assets/icons/ic_eye_green.svg');
                }
                #{$root}_item-heartGray & {
                    background-image: url('~assets/icons/ic_heart_green.svg');
                }
                #{$root}_item-power & {
                    background-image: url('~assets/icons/ic_power_green.svg');
                }
            }
        }
    }

    &_icon {
        width: rem(20);
        margin-right: rem(8);
    
        .a-icon {
            transition: 0.3s ease-in-out;
        }
    }
}
