.m-titlePage {
    display: flex;
    align-items: center;
    padding: rem(16) 0 rem(12);

    &_left {
        width: rem(176);
        flex-shrink: 0;
        margin-right: rem(40);
    }

    &_right {
        padding-left: rem(40);
        border-left: 1px solid $isabelline2;
        flex-grow: 1;
        @include adjust-flex(space-between);
    }
}
