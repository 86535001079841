.o-popupDiscount {
    display: flex;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: rem(3);
        left: 0;
        bottom: 0;
        z-index: 3;
        background-color: $north-texas-green;
    }

    &_img {
        flex: 0 0 44.25%;
        max-width: 44.25%;

        .o-player {
            background-color: $north-texas-green;
        }
    }

    &_container {
        flex: 0 0 55.75%;
        max-width: 55.75%;
        padding: rem(10) rem(40);
        display: flex;
        flex-direction: column;
        justify-content: center;

        &_code {
            padding: rem(11);
            border: 1px solid $gainsboro;
        }

        &_action {
            display: flex;
            margin-left: rem(-5);
            margin-right: rem(-5);
        }

        &_btn {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 rem(5);
        }
        
        &_nav {
            @include adjust-flex;
    
            &_btn {
                @include reset-button;
                @include adjust-flex;
                background-color: transparent;
                width: rem(32);
                height: rem(32);
                border-radius: 50%;
                padding: 0;
                box-shadow: 0 rem(2) rem(8) rgba($charcoal, 0.2);

                &:hover {
                    box-shadow: 0 rem(2) rem(8) rgba($charcoal, 0.5);
                }
            }
        }
    }

}
