.p-newLogin_signin {
    @include adjust-flex;

    &_logo {
        max-width: rem(286);
    }

    form {
        flex: 0 0 100%;
    }
}
