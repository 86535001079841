.a-checkbox {
    $root: &;
  
    &_label {
        align-items: center;
        cursor: pointer;
        display: flex;
        min-height: rem(20);
        position: relative;
        &:hover #{$root}_text {
            opacity: 0.8;
        }
    }
  
    input {
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
    }
  
    &_text {
        @include font-base(14,20);
        color: $eerie-black;
        transition: $transition ease;
        font-weight: 500;
    }
  
    &_checkMark {
        background-position: center;
        background-repeat: no-repeat;
        height: rem(18);
        margin-right: rem(11);
        transition: all $transition ease-in-out;
        width: rem(18);
        flex-shrink: 0;
    }

    &-green {
        input {
            &:checked + #{$root}_checkMark {
                background-image: url('~assets/icons/ic_checkbox_checked.svg');
            }
        }

        #{$root}_checkMark {
            background-image: url('~assets/icons/ic_checkbox_uncheck.svg');
        }
    }

    &-blue {
        input {
            &:checked + #{$root}_checkMark {
                background-image: url('~assets/icons/ic_checkbox_checked_blue.svg');
            }
        }

        #{$root}_text {
            color: $dodger-blue;
            margin-left: rem(8);
        }

        #{$root}_checkMark {
            background-image: url('~assets/icons/ic_checkbox_uncheck_blue.svg');
        }
    }

    &-gray {
        input {
            &:checked + #{$root}_checkMark {
                background-image: url('~assets/icons/ic_checkbox_checked_blue.svg');
            }
        }

        #{$root}_checkMark {
            background-image: url('~assets/icons/ic_checkbox_uncheck_gray.svg');
        }
    }
}
  
.a-productCheckbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_quantity {
        @include font-base(14,20);
        color: $eerie-black;
        transition: $transition ease;
        font-weight: 600;
        flex-shrink: 0;
        margin-left: rem(4);
    }
}
