.m-pagination {
    @include adjust-flex(flex-end);

    .main {
        display: flex;
    }
  
    .page {
        background: $white;
        border: 1px solid $gainsboro;
        border-radius: rem(2);
        box-sizing: border-box;
        cursor: pointer;
        height: rem(40);
        list-style-type: none;
        margin: 0 rem(6);
        min-width: rem(40);
        overflow: hidden;
  
        &:first-child {
            margin-left: 0;
        }
  
        &:last-child {
            margin-right: 0;
        }
  
        &:not(.disabled):hover {
            background-color: $aero-blue;
            border-color: $aero-blue;
  
            .link-page {
                color: $north-texas-green;
            }
        }
  
        .link-page {
            @include adjust-flex;
  
            @include font-base(14, 20);
            color: $davys-grey;
            font-weight: 600;
            height: 100%;
            outline: none;
            padding: 0;
            width: 100%;
  
            @include tablet-down {
                @include font-base(12, 16);
            }
        }

        .link-break {
            align-items: flex-end;
            padding-bottom: rem(5);
        }
    }
  
    .disabled {
        background-color: $gray-x11;
        border-color: $gray-x11;
        opacity: 0.7;
        pointer-events: none;
    }
  
    .active-page {
        background: $aero-blue;
        border: 1px solid $aero-blue;
  
        .link-page {
            color: $north-texas-green;
        }
    }
}
