.o-popupStore {
    @include  adjust-flex;
    flex-direction: column;
    padding: rem(20);

    &::after {
        content: '';
        position: absolute;
        height: 3px;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $north-texas-green;
    }
}
