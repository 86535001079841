.o-productTable {
    &_table {
        width: 100%;
        border: 1px solid $isabelline2;

        thead {
            border-bottom: 1px solid $isabelline2;
        }

        th,
        td {
            padding: rem(12);

            &:first-child {
                min-width: rem(300);
            }

            &:nth-child(3) {
                min-width: rem(120);
            }

            &:nth-child(4) {
                min-width: rem(200);
            }
        }

        th {
            &:nth-child(2) ,
            &:nth-child(3) {
                .a-paragraph {
                    text-align: center;
                }
            }

            &:nth-child(4) {
                .a-paragraph {
                    text-align: right;
                }
            }
        }
            
        tbody {
            tr {
                &:nth-child(even) {
                    background-color: $white;  
                }

                &:nth-child(odd) {
                    background-color: $white-smoke1;
                }
            } 
        }

    }

    &_wrapListDiscount {
        display: flex;
        justify-content: flex-end;
    }

    &_itemDiscount {
        width: fit-content;
        background: $dodger-blue005;
        border: 1px solid $dodger-blue;
        padding: rem(4) rem(8);
        border-radius: rem(3);
    }

    &_item {
        &_basicInfo {
            @include adjust-flex(flex-start, center);
        }

        &_image {
            width: rem(80);
            flex-shrink: 0;
            position: relative;
        }
        
        &_listIcon {
            position: absolute;
            bottom: rem(5);
            left: 0;
        }
    }
}
