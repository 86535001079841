.p-home {
    &_banner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &_left {
            flex: 0 0 rem(176);
            max-width: rem(176);
        }
        
        &_right {
            flex: 0 0 calc(100% - 184px);
            max-width: calc(100% - 184px);
        }
    }

    &_categoryFilter {
        height: 100%;
        border: 1px solid $isabelline2;

        &_item {
            display: block;
            padding: rem(12) rem(5) rem(12) rem(20);
            border-bottom: 1px solid $isabelline2;

            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                .a-paragraph {
                    color: $north-texas-green;
                }
            }
        }
    }

    &_related {
        margin-bottom: rem(-20);

        .slick-list {
            margin: rem(-10);
        }
          
        .slick-slide {
            padding: rem(10);
        }
    }
}
