.o-floatingPhone {
    background-color: $orange-bronze;
    border-radius: 50%;
    bottom: rem(84);
    height: rem(48);
    position: fixed;
    right: rem(30);
    // transform: translateY(rem(68));
    transition: 0.3s linear;
    width: rem(48);
    z-index: $z-scroll-to-top;
    @include adjust-flex;

    &.active {
        transform: translateY(0);
    }

    &_wrapper {
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translate(rem(-10), -50%);
        opacity: 0;
        transition: 0.3s linear;

        &-active {        
            opacity: 1;
            transform: translate(0, -50%);
        }
    }

    &_content {
        cursor: pointer;
        background-color: $white;
        padding: rem(8) rem(12);
        border-radius: rem(500);
        margin-right: rem(10);
        box-shadow: 0 rem(30) rem(84) $black0043, 0 rem(8) rem(32) $black0043, 0 rem(3) rem(14) $black0043,
            0 1px rem(13) $black0043;
    }

    &_button {
        cursor: pointer;
        height: rem(48);
        width: rem(48);
        @include adjust-flex(center, center);
    }
}
