@font-face {
    font-family: $font-inter;
    src: url('#{$font-path}Inter-Bold.woff2') format('woff2'),
        url('#{$font-path}Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-inter;
    src: url('#{$font-path}Inter-Black.woff2') format('woff2'),
        url('#{$font-path}Inter-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-inter;
    src: url('#{$font-path}Inter-Medium.woff2') format('woff2'),
        url('#{$font-path}Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-inter;
    src: url('#{$font-path}Inter-Light.woff2') format('woff2'),
        url('#{$font-path}Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-inter;
    src: url('#{$font-path}Inter-SemiBold.woff2') format('woff2'),
        url('#{$font-path}Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-inter;
    src: url('#{$font-path}Inter-ExtraBold.woff2') format('woff2'),
        url('#{$font-path}Inter-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-inter;
    src: url('#{$font-path}Inter-ExtraLight.woff2') format('woff2'),
        url('#{$font-path}Inter-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-inter;
    src: url('#{$font-path}Inter-Regular.woff2') format('woff2'),
        url('#{$font-path}Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-inter;
    src: url('#{$font-path}Inter-Thin.woff2') format('woff2'),
        url('#{$font-path}Inter-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

// Font Open Sans

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('#{$font-path}OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-BoldItalic.woff2') format('woff2'),
        url('#{$font-path}OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-ExtraBold.woff2') format('woff2'),
        url('#{$font-path}OpenSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-Bold.woff2') format('woff2'),
        url('#{$font-path}OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-Italic.woff2') format('woff2'),
        url('#{$font-path}OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-LightItalic.woff2') format('woff2'),
        url('#{$font-path}OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-Light.woff2') format('woff2'),
        url('#{$font-path}OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-Medium.woff2') format('woff2'),
        url('#{$font-path}OpenSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-Regular.woff2') format('woff2'),
        url('#{$font-path}OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-MediumItalic.woff2') format('woff2'),
        url('#{$font-path}OpenSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-SemiBold.woff2') format('woff2'),
        url('#{$font-path}OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-open-sans;
    src: url('#{$font-path}OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('#{$font-path}OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

