.o-tabs {
    $root: &;
    overflow: hidden;

    &_labels {
        position: relative;
        overflow-x: auto;
        display: inline-flex;
        align-items: center;
        max-width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &_tab {
        padding: rem(20) rem(20) rem(20) 0;
        white-space: nowrap;

        &:last-child {
            padding-right: 0;
        }

        &-active {
            #{$root}_label {
                color: $eerie-black;

                &::before {
                    max-width: 100%;
                }
            }
        }
    }

    &_label {
        position: relative;
        padding-bottom: rem(10);
        color: $spanish-gray;
        @include font-base(16, 22);
        font-weight: 600;
        cursor: pointer;


        &::before {
            content: '';
            bottom: 0;
            width: 100%;
            transition: all $transition ease-in-out;
            background-color: $north-texas-green;
            height: rem(2);
            position: absolute;
            max-width: 0;
        }
  
        &:hover {
            color: $eerie-black;
        }
    }

    &_panel {
        display: none;

        &-active {
            display: block;
        }
    }

    &-underLine {
        #{$root}_tab {
            position: relative;
    
            &::before {
                content: '';
                width: 100%;
                background: $white-smoke;
                height: rem(4);
                position: absolute;
                bottom: rem(13);
            }
        }
    
    
        #{$root}_label {
            position: relative;
    
            &::before {
                content: '';
                height: rem(4);
                position: absolute;
            }
        }
    }
}
