.a-title {
    @include style-color();
    @include style-font-weight();
    @include style-general();
    @include style-fonts();
    margin: 0;
    transition: $transition ease;
    font-family: $font-open-sans;

    // &-h1 {
    //     @include font-base(64, 84);
    // }

    // &-h2 {
    //     @include font-base(48, 64);
    // }

    &-h3 {
        @include font-base(40, 56);
    }

    &-h4 {
        @include font-base(32, 48);
    }

    &-h5 {
        @include font-base(28, 38);
    }

    &-h6 {
        @include font-base(24, 32);
    }
}
