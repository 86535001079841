.o-player {
    height: 0;
    position: relative;

    video {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }
    
    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }


    &-16x9 {
        @include aspect-ratio(16, 9);
    }

    &-1x1 {
        @include aspect-ratio(1,1);
    }

    &-741x416 {
        @include aspect-ratio(741,416);
    }
}
