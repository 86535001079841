.a-relationshipTag {
    @include adjust-flex(flex-start, center);

    border: 1px solid $isabelline;
    border-radius: rem(4);
    padding: rem(2) rem(4);
    user-select: none;

    &-silver {
        color: $gray-pastel;
    }

    &-golden {
        color: $orange-bronze;
    }

    &-diamond {
        color: $blue-piction;
    }

    &-closest {
        color: $north-texas-green;
    }

    svg {
        margin-bottom: rem(1);
        margin-right: rem(5);
    }
}
