.o-scrollToTop {
    background-color: $north-texas-green;
    border-radius: 50%;
    bottom: rem(20);
    cursor: pointer;
    height: rem(48);
    opacity: 0;
    position: fixed;
    right: rem(30);
    transform: translateY(200%);
    transition: 0.3s linear;
    visibility: hidden;
    width: rem(48);
    z-index: $z-scroll-to-top;
    @include adjust-flex;
   
    &:hover img {
        animation: run_arrow 0.7s linear infinite;
    }
  
    &.active {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
}
  
@keyframes run_arrow {
    0% {
        transform: translateY(30%);
    }
  
    100% {
        opacity: 0.3;
        transform: translateY(-30%);
    }
}
