.o-downloadPopup {
    position: fixed;
    z-index: $z-download-popup;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($black, 0.5);

    &_wrapper {
        padding: rem(40) rem(20) rem(20);
        background-color: $white;
        border-bottom: rem(3) solid $north-texas-green;
        max-width: rem(343);
        width: 100%;
        @include small-mobile {
            max-width: 85%;
        }
    }

    &_logo {
        @include adjust-flex(center, center);
        border-radius: rem(16);
        border: rem(1) solid $isabelline2;
        width: rem(86);
        height: rem(86);
        padding: 0 rem(6);
        margin: 0 auto;
    }

}
