@import './Container/Info/';
@import './Container/Comment/';
@import './Container/Content/';
@import './Container/Mentor/';

.p-detailCourse {
    &_content {
        background-color: $white-smoke2;
        margin-bottom: rem(-40);
    }
}
