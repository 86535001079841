.p-orderSchedule {
    padding-bottom: rem(46);

    &_loading {
        margin-top: rem(50);

        .a-icon {
            margin: auto;
        }
    }

    &_row {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 0 rem(-24);

        &::after {
            height: 100%;
            width: 1px;
            position: absolute;
            left: 66%;
            top: 0;
            transform: translateX(50%);
            content: '';
            background-color: $isabelline2;
        }
    }

    &_left {
        max-width: 66%;
        flex-shrink: 0 0 66%;
        width: 100%;
        padding: 0 rem(24);
    }

    &_right {
        max-width: 34%;
        flex: 0 0 34%;
        width: 100%;
        padding: 0 rem(24);
    }

    &_empty_image {
        max-width: rem(214);
        margin: 0 auto;
    }

    &_buttonShowMore {
        max-width: rem(200);
        margin: rem(20) auto 0 auto;
    }

    .hasOrder,
    .draftOrder,
    .selectedOrder {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: rem(-16);
            transform: translate(-50%, -50%);
        }
    }

    .hasOrder {
        &::before {
            width: rem(10);
            height: rem(2);
            background-color: $orange-bronze;
        }
    }

    .draftOrder {
        &::before {
            width: rem(10);
            height: rem(2);
            background-color: $spanish-gray;
        }
    }

    .selectedOrder {
        &::before {
            width: rem(10);
            height: rem(10);
            border-radius: rem(2);
            background-color: $north-texas-green;
        }
    }
}
