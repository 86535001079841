.gridByFive {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-10);
    
    &_item {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 0 rem(10);
    }
}
