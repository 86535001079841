.a-toastify {
    $root: &;
 
    &_content {
        width: fit-content;
        align-items: center;
        display: flex;
        height: 100%;
        padding: rem(12) rem(20);
        margin-left: auto;

        &-success {
            background-color: $eerie-black;
        }
  
        &-error {
            background-color: $red-pelati;
        }
  
    }

    &_icon {
        flex-shrink: 0;
        margin-right: rem(10);
    }
  
    .Toastify {
        &__toast {
            padding: 0;
            box-shadow: none;
            min-height: 0;
            margin-top: rem(20);
            margin-bottom: 0;
            background-color: transparent;
            
            &-container {
                max-width: rem(350);

                &--bottom-right {
                    bottom: rem(129);
                    right: rem(40);
                }
            }
  
            &--default {
                border-radius: 0;
                padding: 0;
            }
  
            &-body {
                margin: 0;
                padding: 0;
            }
        }
    }
}
