// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$white-smoke: rgb(245, 245, 245); // #F5F5F5
$white-smoke248: rgb(248, 248, 248); // #F8F8F8
$black: rgb(0, 0, 0); // #000000
$gray-pastel: rgb(204, 204, 204); // #ccc
$dim-gray: rgb(102, 102, 102); // #666666
$blue-piction: rgb(86, 180, 234); // #56b4ea
$red-pelati: rgb(238, 83, 83); // #EE5353
$orange-bronze: rgb(233, 130, 59); // #e9823b
$gray: rgb(124, 124, 124); // #7c7c7c
$eerie-black: rgb(26, 26, 26); // #1a1a1a
$spanish-gray: rgb(153, 153, 153); // #999999
$error: rgb(206, 49, 0); // #ce3100
$jet: rgb(51, 51, 51); // #333333
$jet2: rgb(48, 48, 48); // #303030
$isabelline: rgb(239, 239, 239); // #efefef
$isabelline2: rgb(235, 235, 235); // #ebebeb
$north-texas-green: rgb(0, 131, 62); // #00833e
$pastel-gray: rgb(204, 204, 204); // #cccccc
$gray-x11: rgb(191, 191, 191); // #bfbfbf
$charcoal: rgb(50, 56, 78); // #32384e
$licorice: rgb(20, 20, 20); // #141414
$davys-grey: rgb(89, 89, 89); // #595959
$anti-flash-white: rgb(240, 240, 240); // #f0f0f0
$glitter: rgb(230, 241, 248); // #e6f1f8
$raisin-black: rgb(38, 38, 38) ; // #262626
$auro-meta-saurus: rgb(102, 112, 133); // #667085
$aero-blue: rgb(209, 255, 231); // #d1ffe7
$gainsboro: rgb(217, 217, 217); // #d9d9d9
$white-smoke1: rgb(246, 246, 246); //#f6f6f6
$dark-jungle-green: rgb(16, 24, 40); //#101828
$medium-sea-green: rgb(59, 159, 107); //#3b9f6b
$white-smoke2: rgb(247, 247, 247); //#f7f7f7
$dodger-blue: rgb(24, 144, 255); //#1890ff 
$dark-tangerine: rgb(250, 173, 20); //#faad14
$dartmouth-green: rgb(0, 123, 58);//#007B3A
$green-crayola: rgb(29, 175, 98); //#1daf62
$rosso-corsa: rgb(214, 0, 0); //#d60000
$go-green: rgb(0, 191, 90); //#00bf5a
$sangria: rgb(150, 0, 9); //#960009
$light-brilliant-red: rgb(255, 50, 50); //#ff3232
$dodger-blue005: rgba($dodger-blue, 0.05);

// Overlays
$black085: rgba($black, 0.85);
$white02: rgba($white, 0.2);
$white04: rgba($white, 0.4);
$charcoal02: rgba($charcoal, 0.2);
$orange-bronze05: rgba($orange-bronze, 0.5);
$black05: rgba($black, 0.5);
$eerie-black04: rgba($eerie-black, 0.4);
$north-texas-green02: rgba($north-texas-green, 0.2);
$jet203: rgba($jet2, 0.3);
$black0043: rgba($black, 0.043);
$black015: rgba($black, 0.15);
$black007: rgba($black, 0.07);
$eerie-black02: rgba($eerie-black, 0.2);
$black03: rgba($black, 0.3);
$white095: rgba($white, 0.95);
$green-crayola005: rgba($green-crayola, 0.05);
$black02: rgba($black, 0.2);
$light-brilliant-red02: rgba($light-brilliant-red, 0.2);

// Linears
