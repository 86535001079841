.m-calendar {
    &_header {
        @include adjust-flex(space-between);

        &_btnPrev,
        &_btnNext {
            width: rem(32);
            height: rem(32);
            @include adjust-flex;
            border: 2px solid $isabelline2;
            border-radius: 8px;
        }

        &_btn {
            position: relative;
            padding: 0 rem(20);
       
        }

        &_icon {
            transition: $transition linear;

            &.open {
                transform: rotate(180deg);
            }
        }

        &_dropdown {
            position: absolute;
            top: calc(100% + 20px);
            left: 0;
            width: 100%;
            border: 1px solid $isabelline2;
            padding: rem(15);
            background-color: $white;
            transition: $transition ease-in-out;
            visibility: hidden;
            opacity: 0;
            z-index: 2;

            &.open {
                top: calc(100% + 10px);
                opacity: 1;
                visibility: visible;
            }
        }

        &_list {
            max-height: rem(196);
            list-style: none;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            li {
                padding: rem(5) 0;
            }
        }
    }
  
    &_calendar {
        background-color: $white;
  
        .react-datepicker__day--in-range,
        .react-datepicker__day--in-selecting-range {
            background-color: $north-texas-green02;
        }
  
        .react-datepicker__day-names {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
            color: $dim-gray;
            @include font-base(16,22);
            font-weight: 600;
        }
  
        .react-datepicker__day--outside-month,
        .react-datepicker__day--disabled {
            color: $pastel-gray !important;
        }
  
        .react-datepicker__day--outside-month {
            visibility: hidden;
        }

        .react-datepicker__month {
            margin: 0;
        }
  
        .react-datepicker__day-name {
            @include font-base(14, 19);
            align-items: center;
            color: $spanish-gray;
            display: flex;
            flex-direction: column;
            font-weight: 600;
            height: rem(40);
            justify-content: center;
            width: rem(40);
            margin-top: rem(12);
        }
  
        .react-datepicker__week {
            display: flex;
            justify-content: space-between;
        }
  
        .react-datepicker__day {
            align-items: center;
            color: $eerie-black;
            display: flex;
            flex-direction: column;
            font-weight: 600;
            height: rem(40);
            justify-content: center;
            width: rem(40);
            @include font-base(16,22);
            cursor: pointer;
            margin-top: rem(12);
        }
  
        .react-datepicker__day--today {
            color: $north-texas-green;
            font-weight: 600;
        }
  
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--selected {
            background: $north-texas-green;
            color: $white;
            border-radius: rem(8);
        }

        .react-datepicker__day--highlighted {
            position: relative;
            font-weight: 600;

            &::before {
                content: '';
                position: absolute;
                width: rem(10);
                height: rem(2);
                background-color: $orange-bronze;
                border-radius: 1px;
                bottom: rem(5);
                left: 50%;
                transform: translateX(-50%);
            }

            &-draft {
                &::before {
                    background-color: $spanish-gray;
                }
            }
        }
    }
  
    &_wrapper {
        border: 0;
        outline: none;
        width: 100%;
    }

    .m-pulldown {
        $pulldown: &;

        &_header {
            border: 0;

            &_content {
                color: $gray;
            }
        }

        &_wrapper {
            min-width: fit-content;
        }

        &_fn {
            margin-left: rem(10);
        }
    }
}
