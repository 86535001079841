.o-accoutnInfoForm {
    &_delete {
        .a-button {
            justify-content: flex-start;

            &:hover {
                box-shadow: none;
            }
        }
    }

    .o-modal_body {
        padding: rem(24);
    }
}
